<template>
   <v-container fluid grid-list-xl style="height: 100%;" py-0>
     <v-layout column justify-start align-space-between fill-height>
       <v-flex xs1 d-flex class="pb-0">
         <v-container pa-0>
           <v-layout row wrap justify-start align-center>
             <v-flex
               v-for="button in tabs"
               :key="button.name"
               shrink
             >
               <v-btn
                 active-class="active"
                 :class="{ 'active': $route.name === button.to.name }"
                 :to="button.to"
               >
                 {{ button.name }}
               </v-btn>
             </v-flex>
           </v-layout>
         </v-container>
       </v-flex>
       <v-flex xs11 fill-height d-flex class="py-0">
          <v-container pa-0 ma-0 fluid>
            <v-layout column justify-space-between align-space-between fill-height>
              <v-flex xs12 fill-height d-flex class="pb-0">
                <v-scale-transition>
                  <component :is="component"/>
                </v-scale-transition>
              </v-flex>
            </v-layout>
          </v-container>
       </v-flex>
     </v-layout>
   </v-container>
</template>

<script>
import gridWrapper from '@/components/wrappers/gridWrapper'
import UserManagement from '@/views/settings/UserManagement'

export default {
  name: 'permissions',
  components: {
    'grid-wrapper': gridWrapper,
    'user-management': UserManagement
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    component: {
      type: String,
      required: true
    }
  }
}
</script>
