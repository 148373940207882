


























import { Mutation, MutationUpdate__Profile_ProfileArgs, Users_User } from '@/models/generated/graphql/ErpBackend'
import CancelButton from '../buttons/CancelButton.vue'
import SubmitButton from '../buttons/SubmitButton.vue'
import { UPDATE_USER_PROFILE } from '@/api/graphql/Constants/Users'
import { defineComponent, provide, Ref, ref } from '@vue/composition-api'
import { ApolloClients, useMutation, UseMutationReturn } from '@vue/apollo-composable'
import store from '@/store/store'
import { apolloClient } from '@/api/graphql/apollo'

export default defineComponent({
  name: 'EditUserCommissions',
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    user: {
      type: Object as () => Users_User,
      required: true
    }
  },
  setup (props) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const commission: Ref<boolean> = ref(props.user.profile?.commission_percentage ?? '')
    const success: Ref<boolean> = ref(false)
    const { mutate: EditCommissionMutation, loading: isLoading, onDone, onError }:
      UseMutationReturn<Mutation, MutationUpdate__Profile_ProfileArgs> = useMutation(
        UPDATE_USER_PROFILE,
        () => ({
          variables: {
            input: {
              id: props.user.profile!.id,
              ...(commission.value && { commission_percentage: commission.value })
            }
          }
        })
      )
    onDone(() => {
      success.value = true
      store.dispatch('notifications/createSnackbar', {
        message: 'Success!',
        color: 'success',
        timeout: 4000
      })
    })
    onError(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not update commission for user.',
        color: 'error',
        timeout: 4000
      })
    })

    return {
      commission,
      isLoading,
      EditCommissionMutation,
      success
    }
  }
})
