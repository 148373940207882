<template>
  <div>
    <grid-wrapper
      :columns="columns"
      :component-name="$options.name"
      :context-menu-items="contextMenuItems"
      :query="query"
      :filter="filter"
    />
    <v-dialog
      v-model="dialog"
      lazy
      persistent
      max-width="400px"
    >
      <component v-if="dialog" v-bind:user="user" :is="component" @close="dialog = false"/>
    </v-dialog>
  </div>
</template>

<script>
import gridWrapper from '@/components/wrappers/gridWrapper'
import { GET_USERS__GRID } from '@/api/graphql/Constants/Permissions'
import { COLUMNS__USER_MANAGEMENT } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__USER_MANAGEMENT } from '@/lib/agGridContextMenuConfigurations'
import EditUserCommissions from '@/components/dialogs/EditUserCommissions'

export default {
  name: 'UserManagement',
  components: {
    'grid-wrapper': gridWrapper,
    'edit-commissions': EditUserCommissions
  },
  data () {
    return {
      query: GET_USERS__GRID,
      filter: [],
      columns: COLUMNS__USER_MANAGEMENT,
      contextMenuItems: params => CONTEXT_MENU__USER_MANAGEMENT(params, this),

      component: '',
      dialog: false,
      user: {
        id: 0,
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        profile: {
          id: 0,
          commission_percentage: ''
        }
      }
    }
  },
  methods: {
    editUserCommissions (user) {
      this.user = user
      this.component = 'edit-commissions'
      this.dialog = true
    }
  }
}
</script>
